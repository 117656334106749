<template>
  <v-row justify="center">
    <v-dialog :value="open" @click:outside="handleClose" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <template v-if="editedItem.id">Edit Topic</template>
          <template v-else>Create Topic</template>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-text-field v-model.trim="editedItem.name" label="Topic name" hide-details></v-text-field>
          </v-container>
          <v-container fluid>
            <span>Tags</span>
            <template>
              <v-container fluid class="mt-3">
                <v-row align="center">
                  <v-combobox
                    v-model="editedItem.tags"
                    :items="tagsList"
                    label="Choose suitable tags..."
                    item-text="name"
                    multiple
                    outlined
                    clearable
                    chips
                    hide-details
                  ></v-combobox>
                </v-row>
              </v-container>
            </template>
          </v-container>
        </v-card-text>

        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn text rounded @click.stop="handleClose">
            Cancel
          </v-btn>
          <v-btn text rounded @click.stop="submit">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const getDefaultState = () => ({
  editedItem: {
    name: '',
    tags: []
  }
});

export default {
  props: {
    open: Boolean,
    data: Object
  },
  data: () => getDefaultState(),
  computed: {
    tagsList() {
      return this.$store.state.tags.list;
    }
  },
  mounted() {
    this.$store.dispatch('tags/load');
  },
  updated() {
    if (this.$props.data) this.$data.editedItem = this.$props.data;
  },

  methods: {
    handleClose() {
      this.$emit('onClose', this.$data.editedItem);
      Object.assign(this.$data, getDefaultState());
    },

    async submit() {
      const dataToSend = {
        name: this.editedItem.name,
        ids: this.editedItem.tags.map(tag => tag.id)
      };

      if (dataToSend.name.trim().length) {
        if (this.editedItem.id) {
          dataToSend.id = this.editedItem.id;
          await this.$store.dispatch('topics/edit', dataToSend);
        } else {
          await this.$store.dispatch('topics/create', dataToSend);
        }
        this.handleClose();
      } else {
        window.alert('Topic name cannot be empty! Please, enter valid topic name');
      }
      await this.$store.dispatch('topics/load');
    }
  }
};
</script>
